import storage from "services/storage";
import http from "./http-common";

const getSingleUser = (id) => {
  return http.get(`/user/${id}`, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};

const createUser = (data) => {
  return http.post("/user", data, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};
const enable = (data) => {
  return http.patch(`/user`, data, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};
const suspicious = (id) => {
  return http.put(`/user/toggle-suspicious/${id}`, {}, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};

const edit = (id, data) => {
  return http.post(`/user/editUser/${id}`, data, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};
const getNote = (id) => {
  return http.get(`/user/getnotes/${id}`, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};

const addNote = (data) => {
  return http.post(`/user/addnotes`, data, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};
const deleteNote = (id) => {
  return http.delete(`/user/cleanNote/${id}?isDeleted=true`, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};

const flag = (data) => {
  return http.patch(`/user/isflag`, data, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};
const cashout = (id, data) => {
  return http.post(`/user/analytics/${id}`, data, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};
const betsOverView = (userId, data) => {
  return http.post(`/user/revenue-data/${userId}`, data, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};
const jumioAttempt = (id, data) => {
  return http.put(`/user/jumio/${id}`, data, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};
const manualTransaction = (id, data) => {
  return http.post(`/user/manual-purchase/${id}`, data, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};

const manualCashout = (id, data) => {
  return http.post(`/user/manual-cashout/${id}`, data, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};

const purchaseSettings = (id, data) => {
  return http.put(`/user/set-limit/${id}`, data, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};

const verifiedUsers = () => {
  return http.get(`/partner/user-stats`,  {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};

const getSpins = ({id, perPage, page, startDate, endDate}) => {
  return http.get(`/user/wheel-spins/${id}?page=${page}&perPage=${perPage}&startDate=${startDate}&endDate=${endDate}`,  {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};
const SingleUserService = {
  getSingleUser,
  createUser,
  enable,
  edit,
  getNote,
  addNote,
  deleteNote,
  flag,
  cashout,
  betsOverView,
  jumioAttempt,
  manualTransaction,
  manualCashout,
  verifiedUsers,
  getSpins,
  suspicious,
  purchaseSettings,
};

export default SingleUserService;
